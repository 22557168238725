<template>
   <div></div>
</template>

<script>
  export default {
    name: "indexcopy",
    data:()=>({}),
    mounted(){
      this.$router.replace({ name: "PersonalPage",})
    }
  };
</script>

<style scoped>

</style>
